import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Container from '../Container'
import media from '../../common/MediaQueries'
import { decorLink, decorHoverLink } from '../../common/mixins'

const StyledIntro = styled(Container)`
  width: calc(100% + 1.875rem * 2);
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  max-width: 50.625rem;
  padding: 2.5rem 1.875rem;
  color: ${({ theme }) => theme.colors.white};
  
  a {
    color: ${({ theme }) => theme.colors.yellow};
    ${decorLink};

    &:hover {
      ${decorHoverLink};
    }
  }

  ${({ bg }) =>
    bg === 'black' &&
    css`
      background: ${({ theme, bgOpacity }) =>
        rgba(theme.colors.black, bgOpacity)};
    `}

    ${({ bg }) =>
      bg === 'lightBlack' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.darkGrey, bgOpacity)};
      `}

    ${({ bg }) =>
      bg === 'white' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.white, bgOpacity)};
        color: ${({ theme }) => theme.colors.black};
      `}

    ${({ bg }) =>
      bg === 'lightGrey' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.lightGrey4, bgOpacity)};
        color: ${({ theme }) => theme.colors.black};
      `}

  @media ${media.phone} {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 4rem 1.875rem;
    text-align: center;
  }  
`

const StyledIntroHeader = styled.div`
  margin-bottom: 1.25rem;

  @media ${media.phone} {
    margin-bottom: 1.875rem;
  }
`

export { StyledIntro, StyledIntroHeader }
