import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import { Heading2 } from '../components/Typography/Typography'
import Intro from '../components/Intro/Intro'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import { generateHero } from '../common/utils'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import HTMLBody from '../components/HTMLBody'

const GentlemensGuidePage = ({ data }) => {
  const page = data.wp.page?.gentlemensguideAcf
  const seoData = data.wp.page?.seo

  const contentBlocks = page.contentBlocks.content

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={[heroBgColorMobile, heroBgColorDesktop]}
        color={[heroColorMobile, heroColorDesktop]}
      >
        <TruncatedHero
          heroType={TRUNCATED_HERO_TYPES.withShortHeroOnMobile}
          heroSlantType={slantDirection ? slantDirection : 'right'}
          heroImages={heroImages}
          gradient={hasBgGradient ? bgGradient : null}
          positionImg="50% 10%"
          long={isHeroHigh}
        />
        <Container
          mt={['0', '-4rem', '-17rem']}
          pb={['', '4rem', '6.25rem']}
          position="relative"
          zIndex={2}
        >
          <Inner>
            <Intro
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: page.heroFields.infoboxTitle,
                  }}
                />
              }
              description={
                <HTMLBody opacity="1" html={page.heroFields.infoboxContent} />
              }
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
            />
          </Inner>
        </Container>
      </Container>

      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
    </Layout>
  )
}

export default GentlemensGuidePage

export const query = graphql`
  query {
    wp {
      page(id: "gentlemens-guide", idType: URI) {
        id
        title
        gentlemensguideAcf {
          heroFields {
            slantDirection
            infoboxTitle
            infoboxContent
            heroHeight
            boxBackgroundOpacity
            boxBackgroundColor
            bgMobile
            bgGradient
            bgDesktop
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Gentlemensguideacf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Gentlemensguideacf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Gentlemensguideacf_ContentBlocks_Content_ModelsSlider {
                fieldGroupName
                bg
                isAlt
              }
            }
          }
        }
        seo {
          ...seoFragment
        }
      }
    }
  }
`
