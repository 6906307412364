import React from 'react'
import PropTypes from 'prop-types'

import { StyledIntro, StyledIntroHeader } from './styles'
import Container from '../Container'
import { BG_TYPES } from '../../constants/bgTypes'

const Intro = ({ bg, bgOpacity, title, description }) => (
  <StyledIntro bg={bg} bgOpacity={bgOpacity}>
    <Container className="uiAnimBottom">
      <StyledIntroHeader>{title}</StyledIntroHeader>
      {description}
    </Container>
  </StyledIntro>
)

export default Intro

Intro.defaultProps = {
  bgOpacity: 1,
  bg: BG_TYPES.darkGrey,
}

Intro.propTypes = {
  bgOpacity: PropTypes.number,
  bg: PropTypes.string,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
}
