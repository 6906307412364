import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import Inner from '../../Inner'
import media, { customMedia } from '../../../common/MediaQueries'
import {
  slantLeft,
  slantRight,
  slantLeftFallback,
  slantRightFallback,
} from '../../../common/mixins'
import {
  TRUNCATED_HERO_TYPES,
  TRUNCATED_HERO_SLANT_TYPES,
  TRUNCATED_HERO_BOX_SIDE_TYPES,
  TRUNCATED_HERO_GRADIENT_TYPES,
} from '../../../constants/truncatedHeroTypes'
import { StyledImage } from '../../Image/styles'

const StyledTruncatedHeroAction = styled.div``

const StyledTruncatedHeroHeader = styled.div`
  max-width: 41.875rem;

  & + ${StyledTruncatedHeroAction} {
    margin-top: 2rem;
  }

  @media ${customMedia.maxTabletSmall} {
    br {
      display: none;
    }

    strong {
      text-transform: uppercase;
    }
  }
`

const StyledTruncatedHeroInner = styled(Inner)`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ centered }) =>
    centered &&
    css`
      ${StyledTruncatedHeroHeader} {
        margin-left: auto;
        margin-right: auto;
      }
    `}

  @media ${media.tabletSmall} {
    position: relative;

    ${({ centered }) =>
      !centered &&
      css`
        justify-content: flex-end;
      `}
  }
`

const StyledTruncatedHeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 1.875rem;
  right: 1.875rem;
  width: calc(100% - (1.875rem * 2));
  transform: translateY(-50%);

  @media ${media.tabletSmall} {
    top: 15.1875rem;
    transform: none;
  }
`

const StyledTruncatedHeroImg = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 37.875rem;
  overflow: hidden;

  ${StyledImage} {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .gatsby-image-wrapper {
      flex-grow: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    background: ${({ theme }) => theme.colors.black};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${media.tabletSmall} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${StyledImage} {
      opacity: ${(props) => props.opacityImg};
    }
  }
`

const StyledTruncatedHeroBox = styled.div`
  margin-left: -1.875rem;
  margin-right: -1.875rem;

  @media ${media.tabletSmall} {
    margin: 0;
  }
`

const StyledTruncatedHeroGradient = styled.span`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ gradient }) =>
    gradient === TRUNCATED_HERO_GRADIENT_TYPES.top &&
    css`
      background: linear-gradient(
        ${({ theme }) => rgba(theme.colors.black, 0.75)},
        transparent
      );
    `}

  ${({ gradient }) =>
    gradient === TRUNCATED_HERO_GRADIENT_TYPES.right &&
    css`
      background: radial-gradient(
        ellipse at top left,
        ${({ theme }) => rgba(theme.colors.black, 0.13)},
        ${({ theme }) => theme.colors.black}
      );
    `}

  ${({ gradient }) =>
    gradient === TRUNCATED_HERO_GRADIENT_TYPES.bottom &&
    css`
      background: linear-gradient(
        transparent,
        ${({ theme }) => rgba(theme.colors.black, 0.75)}
      );
    `}

  ${({ gradient }) =>
    gradient === TRUNCATED_HERO_GRADIENT_TYPES.left &&
    css`
      background: radial-gradient(
        ellipse at top right,
        ${({ theme }) => rgba(theme.colors.black, 0.13)},
        ${({ theme }) => theme.colors.black}
      );
    `}
`

const StyledTruncatedHero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;

  ${({ heroSlantType }) =>
    heroSlantType === TRUNCATED_HERO_SLANT_TYPES.left &&
    css`
      ${StyledTruncatedHeroImg} {
        ${slantLeft}
        ${slantLeftFallback}
      }
    `}

  ${({ heroSlantType }) =>
    heroSlantType === TRUNCATED_HERO_SLANT_TYPES.right &&
    css`
      ${StyledTruncatedHeroImg} {
        ${slantRight}
        ${slantRightFallback}
      }
    `}

  ${({ heroType }) =>
    heroType === TRUNCATED_HERO_TYPES.withShortHeroOnMobile &&
    css`
      @media ${customMedia.maxTabletSmall} {
        ${StyledTruncatedHeroImg} {
          clip-path: none;
          height: 26.375rem;

          &::after {
            display: none;
          }
        }
      }
    `}

  ${({ heroType }) =>
    heroType === TRUNCATED_HERO_TYPES.default &&
    css`
      @media ${customMedia.maxTabletSmall} {
        ${StyledTruncatedHeroInner} {
          height: 37.875rem;
        }
      }

      @media ${customMedia.maxTabletSmall} {
        ${StyledTruncatedHeroImg} {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    `}

  ${({ heroType }) =>
    heroType === TRUNCATED_HERO_TYPES.withGradientOnMobile &&
    css`
      @media ${customMedia.maxTabletSmall} {
        ${StyledTruncatedHeroImg} {
          &::before {
            z-index: 1;
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              to bottom,
              transparent,
              ${({ theme }) => theme.colors.black}
            );
          }

          clip-path: none;

          &::after {
            display: none;
          }
        }
      }
    `}

    ${({ heroType, lightGradient }) =>
    lightGradient &&
    heroType === TRUNCATED_HERO_TYPES.withGradientOnMobile &&
    css`
      @media ${customMedia.maxTabletSmall} {
        ${StyledTruncatedHeroImg} {
          &::before {
            background: linear-gradient(
              to bottom,
              transparent,
              ${({ theme }) => theme.colors.white}
            );
          }
        }
      }
    `}

  @media ${media.tabletSmall} {
    flex-direction: row;
    height: 45.125rem;
    z-index: 2;

    ${({ heroBoxSideType }) =>
      heroBoxSideType === TRUNCATED_HERO_BOX_SIDE_TYPES.right &&
      css`
        ${StyledTruncatedHeroInner} {
          align-items: flex-end;
        }
      `}

    ${({ long }) =>
      long &&
      css`
        height: 64.375rem;
      `}
  }
`

export {
  StyledTruncatedHero,
  StyledTruncatedHeroImg,
  StyledTruncatedHeroInner,
  StyledTruncatedHeroBox,
  StyledTruncatedHeroHeader,
  StyledTruncatedHeroGradient,
  StyledTruncatedHeroAction,
  StyledTruncatedHeroContent,
}
