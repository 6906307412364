import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledTruncatedHero,
  StyledTruncatedHeroImg,
  StyledTruncatedHeroInner,
  StyledTruncatedHeroBox,
  StyledTruncatedHeroHeader,
  StyledTruncatedHeroAction,
  StyledTruncatedHeroGradient,
  StyledTruncatedHeroContent,
} from './styles'
import Image from '../../Image/Image'
import { fluidPropType } from '../../../constants/proptypes'
import {
  TRUNCATED_HERO_TYPES,
  TRUNCATED_HERO_SLANT_TYPES,
  TRUNCATED_HERO_BOX_SIDE_TYPES,
  TRUNCATED_HERO_GRADIENT_TYPES,
} from '../../../constants/truncatedHeroTypes'

const TruncatedHero = ({
  children,
  heroType,
  heroSlantType,
  heroBoxSideType,
  heroImages,
  opacityImg,
  header,
  action,
  positionImg,
  gradient,
  long,
  lightGradient,
  centered,
}) => (
  <StyledTruncatedHero
    {...{ heroType, heroSlantType, heroBoxSideType, long, lightGradient }}
  >
    <StyledTruncatedHeroImg {...{ opacityImg }}>
      {gradient && <StyledTruncatedHeroGradient {...{ gradient }} />}
      <Image fluid={heroImages} objectPosition={positionImg} />
    </StyledTruncatedHeroImg>
    <StyledTruncatedHeroInner {...{ centered }}>
      {(header || action) && (
        <StyledTruncatedHeroContent className="uiAnimBottom">
          {header && (
            <StyledTruncatedHeroHeader>{header}</StyledTruncatedHeroHeader>
          )}
          {action && (
            <StyledTruncatedHeroAction>{action}</StyledTruncatedHeroAction>
          )}
        </StyledTruncatedHeroContent>
      )}
      {children && <StyledTruncatedHeroBox>{children}</StyledTruncatedHeroBox>}
    </StyledTruncatedHeroInner>
  </StyledTruncatedHero>
)

export default TruncatedHero

TruncatedHero.defaultProps = {
  heroType: TRUNCATED_HERO_TYPES.default,
  heroSlantType: TRUNCATED_HERO_SLANT_TYPES.left,
  heroBoxSideType: TRUNCATED_HERO_BOX_SIDE_TYPES.right,
  opacityImg: 1,
  positionImg: '50% 50%',
  lightGradient: false,
  centered: false,
  long: false,
  action: null,
  header: null,
}

TruncatedHero.propTypes = {
  children: PropTypes.node,
  heroType: PropTypes.oneOf(Object.keys(TRUNCATED_HERO_TYPES)),
  heroSlantType: PropTypes.oneOf(Object.keys(TRUNCATED_HERO_SLANT_TYPES)),
  heroBoxSideType: PropTypes.oneOf(Object.keys(TRUNCATED_HERO_BOX_SIDE_TYPES)),
  gradient: PropTypes.oneOf(Object.keys(TRUNCATED_HERO_GRADIENT_TYPES)),
  long: PropTypes.bool,
  heroImages: PropTypes.oneOfType([
    fluidPropType,
    PropTypes.arrayOf(fluidPropType),
  ]),
  header: PropTypes.node,
  action: PropTypes.node,
  opacityImg: PropTypes.number,
  positionImg: PropTypes.string,
  lightGradient: PropTypes.bool,
  centered: PropTypes.bool,
}
