export const TRUNCATED_HERO_TYPES = {
  default: 'default',
  withShortHeroOnMobile: 'withShortHeroOnMobile',
  withGradientOnMobile: 'withGradientOnMobile',
}

export const TRUNCATED_HERO_SLANT_TYPES = {
  left: 'left',
  right: 'right',
}

export const TRUNCATED_HERO_BOX_SIDE_TYPES = {
  left: 'left',
  right: 'right',
}

export const TRUNCATED_HERO_GRADIENT_TYPES = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
}
